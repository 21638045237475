.htmleditor p {
  font-family: Arial;
  font-size: 10pt;
}

.htmleditor a {
  color: black;
  text-decoration-skip-ink: none;
  transition: all 0.3s;
}

.htmleditor a:hover {
  opacity: 0.5;
}

.historical .MuiBox-root .MuiBox-root p {
  margin-top: 4px;
}
