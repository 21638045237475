.loader {
  width: 30px;
  aspect-ratio: 1;
  /* box-shadow: 0 0 0 2px #000 inset; */
  background-color: #000;
  border-radius: 50%;
  position: relative;
  animation: l11 14s infinite;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: calc(100% + 6px);
  left: calc(50% - 6.5px);
  box-shadow: inherit;
  width: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  transform-origin: 50% -21px;
  animation: l11 2.2s infinite;
  /* animation-timing-function: linear; */
}
.loader:before {
  /* box-shadow: 0 0 0 2px #ffe01a inset; */
  background-color: #ffe01a;
}
.loader:after {
  /* box-shadow: 0 0 0 2px #ef492f inset; */
  background-color: #ef492f;
}
.loader:after {
  animation-delay: -1.1s;
}
@keyframes l11 {
  100% {
    transform: rotate(360deg);
  }
}
